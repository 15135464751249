<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    @click:outside="dialog = false"
  >
    <v-card>
      <v-card-title>
        <span class="headline">ユーザ追加</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-autocomplete
            v-model="input"
            :items="users"
            :loading="searchLoading"
            :search-input.sync="search"
            label="ユーザ選択"
            multiple
            chips
            deletable-chips
            item-text="name"
            item-value="user_id"
            :rules="rules.users"
          >
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-avatar>
                  <img :src="data.item.picture">
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog=false">キャンセル</v-btn>
        <v-btn color="primary" @click="send" :loading="loading">アサイン</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios"

export default {
  props: {
    roleid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      searchLoading: false,
      search: null,
      input: [],
      valid: true,
      loading: false,
      users: [],
      rules: {
        users: [
          v => Boolean(v.length) || '選択してください。',
        ],
      },
    }
  },
  watch: {
    search (val) {
      if (val.length === 0) {
        this.users = []
        return
      }

      if (this.searchLoading) return

      this.fetchEntriesDebounced(val)
    }
  },
  methods: {
    open() {
      this.dialog = true
      this.input = []
      this.users = []
      this.$nextTick(() => {
        this.$refs.form.resetValidation()
      })
    },
    send() {
      if (this.$refs.form.validate()) {
        this.save()
      }
    },
    async save() {
      this.loading = true
      let form = { users: this.input }
      const accessToken = await this.$auth.getTokenSilently()
      await axios.post(`/api/roles/${this.roleid}/users`, form, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      .then((response) => {
        this.dialog = false
        this.$emit('success', response.data)
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false
      })
    },
    fetchEntriesDebounced(val) {
      // cancel pending call
      clearTimeout(this._timerId)

      // delay new call 500ms
      this._timerId = setTimeout(() => {
        this.fetch(val)
      }, 500)
    },
    async fetch(val) {
      this.searchLoading = true

      const accessToken = await this.$auth.getTokenSilently()
      const params = {
        page: 0,
        q: `name:*${val}* OR email:*${val}*`,
        sort: 'name:1'
      }
      await axios
        .get(`/api/users`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          params: params,
        })
        .then(response => {
          this.users = response.data.users
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.searchLoading = false;
        });
    }
  }
}
</script>