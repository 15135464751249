<template>
  <v-container>
    <v-btn text @click="toApplicants">
      <v-icon left>mdi-arrow-left</v-icon>
      申請一覧に戻る
    </v-btn>
    <v-row v-if="loading" justify="center" class="mt-16">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-row>
    <v-alert v-else-if="error" type="error">{{ error }}</v-alert>
    <v-container v-else>
      <!-- 申請名 -->
      <h1 class="display-1 font-weight-bold">{{ id }}</h1>

      <v-divider class="mt-4"></v-divider>

      <!-- 申請ユーザ一覧 -->
        <v-data-table
          :headers="headers"
          :items="data.users"
          :loading="loadingUsers"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <div class="mr-6">
                ユーザ数: {{ data.total }}
              </div>
              <!--
              <v-btn
                color="success"
                @click="openAddUser()"
              >
                <v-icon left>
                  mdi-plus
                </v-icon>
                ユーザ追加
              </v-btn>
              -->
            </v-toolbar>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div class="d-flex align-center my-2">
              <v-avatar size="40">
                <img :src="item.picture"/>
              </v-avatar>
              <v-row class="ml-1">
                <v-col>
                  <div class="body-1">
                    <router-link
                      :to="{ name: 'User', params: { id: item.user_id } }"
                      style="text-decoration: none;"
                    >
                      {{ item.name }}
                    </router-link>
                  </div>
                  <div class="body-2">
                    {{ item.email }}
                  </div>
                </v-col>
              </v-row>
            </div>
          </template>
          <!--
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
          -->
        </v-data-table>
        <v-pagination
          v-model="page"
          total-visible="7"
          :length="pageLength"
          @input = "fetchUsers"
        ></v-pagination>
      </v-container>

    <assign-user-to-role-dialog
      ref="add"
      :roleid="id"
      @success="fetchUsers"
    ></assign-user-to-role-dialog>

    <remove-user-from-role-dialog
      ref="delete"
      :roleid="id"
      :user="selectedItem"
      @success="fetchUsers"
    ></remove-user-from-role-dialog>
  </v-container>
</template>

<script>
import axios from "axios"
import AssignUserToRoleDialog from '@/components/AssignUserToRoleDialog'
import RemoveUserFromRoleDialog from '@/components/RemoveUserFromRoleDialog'

export default {
  components: {
    AssignUserToRoleDialog,
    RemoveUserFromRoleDialog
  },
  props: ['id'],
  data() {
    return {
      loading: false,
      loadingUsers: false,
      error: '',
      headers: [
        { text: 'ユーザ', value: 'name', sortable: false },
        { text: '', value: 'actions', sortable: false },
      ],
      page: 1,
      data: {
        users: [],
        total: 0,
        limit: 10,
      },
      role: {
        name: '',
      },
      selectedItem: {},
    }
  },
  computed: {
    pageLength: function() {
      return Math.ceil(this.data.total / this.data.limit)
    }
  },
  created() {
    this.fetchUsers()
  },
  methods: {
    makeParams() {
      let params = {
        page: this.page -1
      }
      return params
    },
    async fetchUsers() {
      this.loadingUsers = true
      const accessToken = await this.$auth.getTokenSilently()
      const params = await this.makeParams()
      await axios
        .get(`/api/applicants/${this.id}/users`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          params: params,
        })
        .then(response => {
          this.data = response.data
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingUsers = false;
        });
    },
    openAddUser() {
      this.$refs.add.open()
    },
    deleteItem(item) {
      this.selectedItem = item
      this.$refs.delete.open()
    },
    toApplicants() {
      this.$router.push({name:'Applicants'})
    }
  }
}
</script>